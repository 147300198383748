.img-container {
  position: relative;
  max-width: 100%;
}

.button-top-left {
  top: 5%;
  left: 4%;
  background: white;
  color: black;
  padding: 1.5rem 2rem;
  border-radius: 40px;
  font-size: 1rem;
  z-index: 1;
}

.testimonials-center {
  font-family: "Open Sans", sans-serif;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 90%;
  max-height: 60%;
}

.testimonials-center p {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-weight: 500;
  font-size: 60px;
}
/* complete */
.user-images-bottom-right {
  bottom: 5%;
  right: 10%;
  display: flex;
  align-items: center;
  font-size: 1rem;
  z-index: 1;
}

.user-images {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
}

.user-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #ab40ff;
  position: relative;
  z-index: 1;
}

.user-img:not(:first-child) {
  margin-right: -20px; /* Adjust for better overlap */
}

.username {
  color: white;
  margin-left: 10px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .button-top-left {
    font-size: 0.9rem;
    padding: 1rem 1.5rem;
  }

  .testimonials-center p {
    font-size: 50px;
  }

  .user-images-bottom-right {
    font-size: 0.9rem;
  }

  .user-img {
    width: 60px;
    height: 60px;
  }

  .user-img:not(:first-child) {
    margin-right: -15px; /* Adjust for better overlap */
  }
}

@media (max-width: 992px) {
  .button-top-left {
    font-size: 0.8rem;
    padding: 0.8rem 1.2rem;
  }

  .testimonials-center p {
    font-size: 40px;
  }

  .user-images-bottom-right {
    font-size: 0.8rem;
  }

  .user-img {
    width: 50px;
    height: 50px;
  }

  .user-img:not(:first-child) {
    margin-right: -12px; /* Adjust for better overlap */
  }
}

@media (max-width: 768px) {
  .button-top-left {
    font-size: 0.7rem;
    padding: 0.6rem 1rem;
  }
  #testimg {
    min-height: 400px;
  }

  .testimonials-center p {
    font-size: 40px;
  }

  .user-images-bottom-right {
    font-size: 0.7rem;
  }

  .user-img {
    width: 40px;
    height: 40px;
  }

  .user-img:not(:first-child) {
    margin-right: -10px; /* Adjust for better overlap */
  }
}

@media (max-width: 576px) {
  .button-top-left {
    font-size: 0.6rem;
    padding: 0.4rem 0.8rem;
  }
  #testimg {
    min-height: 400px;
  }

  .testimonials-center p {
    font-size: 35px;
  }

  .user-images-bottom-right {
    font-size: 0.6rem;
  }

  .user-img {
    width: 30px;
    height: 30px;
  }

  .user-img:not(:first-child) {
    margin-right: -8px; /* Adjust for better overlap */
  }
}
@media (max-width: 453px) {
  .button-top-left {
    font-size: 0.6rem;
    padding: 0.4rem 0.8rem;
  }
  #testimg {
    min-height: 300px;
  }

  .testimonials-center p {
    font-size: 25px;
  }

  .user-images-bottom-right {
    font-size: 0.6rem;
  }

  .user-img {
    width: 30px;
    height: 30px;
  }

  .user-img:not(:first-child) {
    margin-right: -8px; /* Adjust for better overlap */
  }
}
@media (max-width: 364px) {
  .button-top-left {
    font-size: 0.6rem;
    padding: 0.4rem 0.8rem;
  }
  #testimg {
    min-height: 300px;
  }

  .testimonials-center p {
    font-size: 25px;
  }

  .user-images-bottom-right {
    font-size: 0.6rem;
  }

  .user-img {
    width: 30px;
    height: 30px;
  }

  .user-img:not(:first-child) {
    margin-right: -8px; /* Adjust for better overlap */
  }
}
